import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Avatar, Card, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { b64toBlob } from '../../../utils';
import { addProduct, updateProduct, getAddons, getTasteProfiles } from '../../../apis';
import Select from '@mui/material/Select';
import dummy from '../../../assets/images/dummy.jpeg';
import './styles.scss'
import { getIngredients, getLeafCategories } from '../../../apis/admin';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../routes';
import Switch from '@mui/material/Switch';

function ProductEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCatogory] = useState(data?.category_id || -1);
    const [file, setFile] = useState();
    const [categories, setCategories] = useState([]);
    const [addons, setAddons] = useState([]);
    const [tasteProfiles, setTasteProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAddons, setSelectedAddons] = useState({})
    const [ingredients, setIngredients] = useState([])
    const [recommended, setRecommended] = useState(false)
    const [selectedTasteProfiles, setSelectedTasteProfiles] = useState({})
    const [selectedIngredients, setSelectedIngredients] = useState({})
    const [switchValue, setSwitchValue] = React.useState(false);
    const navigate = useNavigate();
    const [extid, setExtid] = useState(0);

    useEffect(() => {
        async function fetchData() {
            setAddons(await getAddons());
            setCategories(await getLeafCategories());
            setTasteProfiles(await getTasteProfiles());
            setIngredients(await getIngredients());
        }
        fetchData();
    }, [])

    useEffect(() => {
        setCatogory(data?.category_id);
        setPrice(data?.price || "");
        setDescription(data?.description || "");
        setTitle(data?.title || "");
        setRecommended(data?.recommended || false);
        setSwitchValue(data?.status || false);
        setExtid(data?.extid || 0);
        setFile();
        let obj = {}
        addons?.map((addon) => { obj[addon.addon_id] = false })
        data?.AddOns?.map((addon) => { obj[addon.addon_id] = true })
        setSelectedAddons(obj)

        obj = {}
        tasteProfiles?.map((tasteProfile) => { obj[tasteProfile.tasteProfile_id] = false })
        data?.TasteProfiles?.map((tasteProfile) => { obj[tasteProfile.tasteProfile_id] = true })
        setSelectedTasteProfiles(obj)

        obj = {}
        console.log(data)
        ingredients?.map((ingredient) => { obj[ingredient.id] = false })
        data?.Ingredients?.map((ingredient) => { obj[ingredient.id] = true })
        setSelectedIngredients(obj)
    }, [data])

    const changeCategory = (event) => {
        setCatogory(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data_ = new FormData(event.currentTarget);
        const updatedProduct = {
            id: data?.product_id,
            title: data_.get("title"),
            price: data_.get("price").replace(",", "."),
            quantity: data_.get("quantity"),
            description: data_.get("description"),
            category: data_.get("category"),
            active: true,
            file: file
        };

        let addons = []
        for (let key of Object.keys(selectedAddons)) {
            if (selectedAddons[key]) {
                addons.push(parseInt(key))
            }
        }

        let tasteProfiles = []
        for (let key of Object.keys(selectedTasteProfiles)) {
            if (selectedTasteProfiles[key]) {
                tasteProfiles.push(parseInt(key))
            }
        }

        let ingredients = []
        for (let key of Object.keys(selectedIngredients)) {
            if (selectedIngredients[key]) {
                ingredients.push(parseInt(key))
            }
        }


        let form = new FormData();
        form.append('title', updatedProduct.title);
        form.append('price', updatedProduct.price);
        form.append('quantity', updatedProduct.quantity);
        form.append('description', updatedProduct.description);
        form.append('category_id', updatedProduct.category);
        form.append('active', data?.active);
        form.append('addons', addons);
        form.append('recommended', recommended)
        // form.append('tasteProfiles', ingredients)
        // form.append('ingredients', tasteProfiles)
        form.append('tasteProfiles', tasteProfiles)
        form.append('ingredients', ingredients)
        form.append('file', file)
        form.append('status', switchValue);
        form.append('extid', extid);

        if (data) {
            setLoading(true);
            const response = await updateProduct(updatedProduct.id, form);
            setLoading(false);
            if (response.code === 200) {
                setText("Product successfully updated!");
                setOpenSucess(true);
            } else {
                console.error("Update Product Error:", response);
                setText("There was an error while updating the product!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addProduct(form);
            setLoading(false);
            if (response.code === 200) {
                setText("Product successfully added!");
                setOpenSucess(true);
            } else {
                console.error("Add Product Error:", response);
                setText("There was an error while adding the product!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({})
    };


    const handleToggleAddon = (id) => {
        let obj = { ...selectedAddons }
        obj[id] = !selectedAddons[id]
        setSelectedAddons(obj)
    }

    const handleToggleTasteProfile = (id) => {
        let obj = { ...selectedTasteProfiles }
        obj[id] = !selectedTasteProfiles[id]
        setSelectedTasteProfiles(obj)
    }

    const handleToggleIngredient = (id) => {
        let obj = { ...selectedIngredients }
        obj[id] = !selectedIngredients[id]
        setSelectedIngredients(obj)
    }

    return (
        <Card sx={{ padding: 2, width: "46%" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={closeBackdrop}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Product" : "Add new Product"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    Translate product name <Switch
                        checked={switchValue}
                        onChange={() => setSwitchValue(!switchValue)}
                    />
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <TextField
                                value={extid}
                                margin="normal"
                                fullWidth
                                id="extid"
                                label="External ID"
                                name="extid"
                                type="number"
                                placeholder="Ext. ID"
                                onChange={(e) => {
                                    setExtid(e.target.value);
                                }}
                            />

                            <TextField
                                value={title}
                                margin="normal"
                                required
                                fullWidth
                                id="title"
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                label="Product name"
                                name="title"
                                autoComplete="title"
                                autoFocus
                            />
                            <TextField
                                value={description}
                                margin="normal"
                                fullWidth
                                multiline
                                id="description"
                                label="Description"
                                name="description"
                                autoComplete="description"
                                autoFocus
                                rows={4}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                        </div>
                        <Box style={{ display: "flex", flexDirection: "column", width: 230 }}>
                            <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => {
                                document.querySelector('input[id="image_input"]').click();
                            }}>
                                <input
                                    type="file"
                                    hidden
                                    id="image_input"
                                    onChange={(e) => { setFile(e.target.files[0]) }}
                                    style={{ display: "none" }}
                                />
                                <img
                                    component="img"
                                    style={{ height: 230, width: 230, objectFit: "cover", borderRadius: 10 }}
                                    alt="Logo"
                                    src={file ? URL.createObjectURL(file) : data?.image ? URL.createObjectURL(b64toBlob(data?.image)) : dummy}
                                />
                            </div>
                        </Box>
                    </div>
                    <TextField
                        value={price}
                        margin="normal"
                        required
                        fullWidth
                        id="price"
                        label="Price"
                        name="price"
                        autoComplete="price"
                        autoFocus
                        onChange={(e) => {
                            setPrice(e.target.value);
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="category"
                            name='category'
                            value={category}
                            label="Category"
                            onChange={changeCategory}
                        >
                            {
                                categories.map((category, key) => {
                                    return (
                                        <MenuItem value={category.category_id}>{category.title}</MenuItem>
                                    );
                                })
                            }
                            <MenuItem sx={{ fontWeight: "bold" }} onClick={() => { navigate(ROUTES.ADMIN.CATEGORIES) }}>{"+ Add a category"}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel id="demo-simple-select-label">Add/remove Add-ons</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="addons"
                            name='addons'
                            label="Add/Remove Add-ons"
                        >
                            {
                                addons?.map((addon, key) => {
                                    return (
                                        <div onClickCapture={(e) => { handleToggleAddon(addon.addon_id); }}>
                                            <ListItem
                                                key={key}
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        checked={selectedAddons[addon.addon_id]}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={"image"}
                                                            src={URL.createObjectURL(b64toBlob(addon.image))}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={`${addon.title} (€${addon.price})`} />
                                                </ListItemButton>
                                            </ListItem>
                                        </div>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel id="demo-simple-select-label">Add/remove Taste Profiles</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="tasteProfiles"
                            name='tasteProfiles'
                            label="Add/Remove Taste Profiles"
                        >
                            {
                                tasteProfiles?.map((tasteProfile, key) => {
                                    return (
                                        <div onClickCapture={() => { handleToggleTasteProfile(tasteProfile.tasteProfile_id) }}>
                                            <ListItem
                                                key={key}
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        checked={selectedTasteProfiles[tasteProfile.tasteProfile_id]}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={"image"}
                                                            src={URL.createObjectURL(b64toBlob(tasteProfile.image))}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={tasteProfile.title} />
                                                </ListItemButton>
                                            </ListItem>
                                        </div>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel id="demo-simple-select-label">Add/remove Ingredients</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="ingredients"
                            name='ingredients'
                            label='Add/Remove Ingredients'
                        >
                            {
                                ingredients?.map((ingredient, key) => {
                                    return (
                                        <div onClickCapture={() => { handleToggleIngredient(ingredient.id) }}>
                                            <ListItem
                                                key={key}
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        checked={selectedIngredients[ingredient.id]}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={"image"}
                                                            src={URL.createObjectURL(b64toBlob(ingredient.image))}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={ingredient.title} />
                                                </ListItemButton>
                                            </ListItem>
                                        </div>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Checkbox checked={recommended} onClick={() => { setRecommended(!recommended) }} />} label="Mark as recommended product" />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update info" : "Add Product"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default ProductEditCard;
