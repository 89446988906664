import { Button, CircularProgress, Fade, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { getUserLanguage, getAllWelcomeMessages } from '../../../apis/common';
import logo from '../../../assets/images/logo.png';

export const Splash = ({ onPress, loading }) => {
    const defaultLanguageId = "Italiano"; // 40 - Italiano
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguageId);    
    //const [selectedLanguage, setSelectedLanguage] = React.useState("");
    const [languages, setLanguages] = React.useState([]);
    const [welcomeMessages, setWelcomeMessages] = useState({}); // State for all welcome messages

    //if local has no language, set default language to defaultLanguageId
    
    // if (!localStorage.getItem('selectedLanguage')) {
    //     localStorage.setItem('selectedLanguage', defaultLanguageId);
    //     Cookies.set('selectedLanguage', defaultLanguageId, { expires: 7 });
    // }

    const handlePress = () => {
        // Check if the selected language is already stored, if not, store it
        if (!localStorage.getItem('selectedLanguage')) {
            setLocalStorage(selectedLanguage);
        }
        // Then call the original onPress function passed as a prop
        onPress();
    };    

    const handleLanguageChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedLanguage(selectedValue);
        console.log("Change language: ", selectedValue);
        localStorage.setItem('selectedLanguage', selectedValue);
        Cookies.set('selectedLanguage', selectedValue, { expires: 7 });
    }; 

    function setLocalStorage(selectedValue) {
        localStorage.setItem('selectedLanguage', selectedValue);
        Cookies.set('selectedLanguage', selectedValue, { expires: 7 });
    }

    // useEffect(() => {
    //     const fetchLanguageData = async () => {
    //         try {
    //             const response = await getUserLanguage(); // Replace with your API call
    //             if (response.code === 200) {
    //                 setLanguages(response.result);
    //                 const storedLanguage = localStorage.getItem('selectedLanguage');
    //                 setSelectedLanguage(storedLanguage || 'Select Language');
    //                 // if (!storedLanguage && response.result.length > 0) {
    //                 //     const firsLanguage = response.result[0].name;
    //                 //     setSelectedLanguage(firsLanguage);
    //                 //     localStorage.setItem('selectedLanguage', firsLanguage);
    //                 //     Cookies.set('selectedLanguage', firsLanguage, { expires: 7 });
    //                 //     window.location.reload();
    //                 // } else {
    //                 //     setSelectedLanguage(storedLanguage || '');
    //                 // }
    //             }
    //         } catch (error) {
    //             console.log('Error fetching language data:', error);
    //         }
    //     };
    //     fetchLanguageData();
    // }, [])

    useEffect(() => {
        const fetchLanguageAndWelcomeData = async () => {
            try {
                const langResponse = await getUserLanguage();
                if (langResponse.code === 200) {
                    setLanguages(langResponse.result);
                }
    
                const welcomeResponse = await getAllWelcomeMessages();
                if (welcomeResponse.code === 200) {
                    const messages = {};
                    welcomeResponse.result.forEach(item => {
                        messages[item.lingua] = item.translated_language;
                    });
                    setWelcomeMessages(messages);
                }
    
                // Assuming the default language ID is correctly set here
                const storedLanguage = localStorage.getItem('selectedLanguage') || defaultLanguageId;
                setSelectedLanguage(storedLanguage);
    
            } catch (error) {
                console.log('Error:', error);
            }
        };
        fetchLanguageAndWelcomeData();
    }, []);
    
    
    const currentWelcomeMessage = welcomeMessages[selectedLanguage] || "";

    return (
        <Box style={{ display: "flex", flex: 1, backgroundColor: '#fff' }}>
            <div style={{ width: '100%', height: '100vh', display: 'flex', flex: 1 }}>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Box style={{ flexDirection: 'column', width: '60%', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ borderRadius: 1, width: 260, height: 265, p: "5", backgroundColor: 'black', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                component="img"
                                sx={{ height: 250, cursor: "pointer" }}
                                alt="Logo"
                                src={logo}
                            />
                        </Box>
                        <Typography style={{ marginTop: 50, textAlign: 'center', fontSize: 20, fontWeight: 'bold', color: '#6F6F6F' }}>
                            {currentWelcomeMessage}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 5, color: '#6F6F6F' }}>
                            <Typography variant="body1" >
                                Language:
                            </Typography>
                            <Select
                                defaultValue='Italiano' //Select Language
                                value={selectedLanguage}
                                onChange={handleLanguageChange}
                                variant="outlined"
                                inputProps={{ 'aria-label': 'language' }}
                                sx={{
                                    ml: 2,
                                    mt: "5",
                                    color: '#6F6F6F',
                                    '& .MuiSelect-select': {
                                        '&:focus': {
                                            backgroundColor: 'transparent',
                                        },
                                    },
                                }}
                            >
                                {/* <MenuItem value="Select Language" disabled>Select Language</MenuItem> */}
                                {languages.map((language) => (
                                    <MenuItem key={language.id} value={language.name}>
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        {
                            loading ? <div style={{ marginTop: 25 }}><CircularProgress size={30} sx={{ color: 'white' }} /></div> : <Fade in={!loading} timeout={700}>
                                <Button disabled={selectedLanguage == 'Select Language'} onClick={handlePress} style={{ backgroundColor: selectedLanguage == 'Select Language' ? '#E8E8E8' : 'black', color: 'white', width: 260, marginTop: 20 }}><Typography style={{ padding: 5, paddingLeft: 70, paddingRight: 70 }}>Start</Typography></Button>
                            </Fade>
                        }
                    </Box>
                </Box>
            </div>
        </Box>
    );
}