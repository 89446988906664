import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'
import { addGroup, updateGroup } from '../../../apis/admin';

function GroupEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTitle(data?.title || "");
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data_ = new FormData(event.currentTarget);
        const updatedAddon = {
            id: data?.id,
            title: data_.get("title"),
        };



        if (data) {
            setLoading(true);
            const response = await updateGroup(updatedAddon.id, { title: updatedAddon.title });
            setLoading(false);
            if (response.code === 200) {
                setText("Variant group successfully updated!");
                setOpenSucess(true);
            } else {
                setText("There was an error while updating the variant group!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addGroup({ title: updatedAddon.title });
            console.log(response);
            setLoading(false);
            if (response.code === 200) {
                setText("Variant group successfully added!");
                setOpenSucess(true);
            } else {
                setText("There was an error while adding the variant group!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({});
    };

    return (
        <Card sx={{ padding: 2, width: "46%" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={closeBackdrop}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Variant Group" : "Add new Variant Group"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <TextField
                            value={title}
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            label="Title"
                            name="title"
                            autoComplete="title"
                            autoFocus
                        />
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update Info" : "Add Variant Group"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default GroupEditCard;
