import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { AddonEditCard } from '../../../components/Admin';
import { b64toBlob } from '../../../utils';
import { getAddons } from '../../../apis';
import SuccessToast from '../../../components/common/SuccessToast';
import FailedToast from '../../../components/common/FailedToast';
import './styles.scss'
import { deleteAddons } from '../../../apis/admin';
import { Link, useNavigate } from "react-router-dom";
import store from '../../../store';
import ROUTES from '../../../routes';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price (€)',
  },
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: 'Image',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.numeric ? 'right' : 'left'}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleDelete } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%', fontFamily: 'Clearface' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
          fontWeight={"bold"}
        >
          Add Ons
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Addons() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [addons, setAddons] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [data, setData] = React.useState();
  const [openSuccess, setOpenSucess] = React.useState(false);
  const [openFailed, setOpenFailed] = React.useState(false);
  const [text, setText] = React.useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    const state = store.getState();
    if (state.mode != "admin") {
      navigate(ROUTES.ADMIN.LOGIN);
    }
    async function fetchData() {
      setLoading(true);
      setAddons(await getAddons());
      setLoading(false);
    }
    fetchData();
  }, [change])

  const handleDelete = async () => {
    const response = await deleteAddons(selected);
    if (response.code === 200) {
      setOpenSucess(true);
      setText("Successfully deleted Add-on[s]");
    } else {
      setOpenFailed(true);
      setText("There was an error in deleting Add-on[s]");
    }
    setChange(!change);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = addons?.map((n) => n.addon_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const closeBackdrop = () => {
    setOpen(false)
  }

  const handleBackDrop = (event, data) => {
    setData(data);
    setOpen(!open);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSucess(false);
    setOpenFailed(false);
  }

  return (
    <Box sx={{ width: '90%', marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Link to="/adminTranslateAddonsLanguage" style={{ textDecoration: 'none' }}>
          <Button color="primary" >
            Edit Translate Addons
          </Button>
        </Link>
        <Button sx={{ alignSelf: "flex-end" }} color="primary" startIcon={<AddIcon />} onClick={handleBackDrop}>
          Add record
        </Button>
      </div>
      <Paper sx={{ width: '100%' }}>
        <EnhancedTableToolbar numSelected={selected.length} handleDelete={handleDelete} />
        <TableContainer>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={addons?.length}
            />
            <TableBody>
              {addons?.map((row, index) => {
                const isItemSelected = isSelected(row.addon_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => { handleBackDrop(event, row) }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.addon_id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => { event.stopPropagation(); handleClick(event, row.addon_id); }}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.title}
                    </TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                    <TableCell align="right">
                      <Box style={{ display: "flex", flexDirection: "column", width: 100 }}>
                        <img
                          component="img"
                          style={{ height: 60, width: 100, objectFit: "cover", borderRadius: 10 }}
                          alt="Logo"
                          src={URL.createObjectURL(b64toBlob(row.image))}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <FormControlLabel
          disabled={addons?.length === 0}
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Paper>

      {
        loading ? <CircularProgress color="inherit" sx={{ mt: 2 }} /> :
          addons?.length === 0 ? <Typography variant='h5' sx={{ textAlign: "center", marginTop: 10, color: "text.secondary", marginBottom: 50 }}>No Add-ons available. Add a Add-on now!</Typography> : <></>
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={toggleBackdrop}
      >
        <AddonEditCard setData={setData} data={data} closeBackdrop={closeBackdrop} change={change} setChange={setChange} setText={setText} setOpenSucess={setOpenSucess} setOpenFailed={setOpenFailed} />
      </Backdrop>
      <SuccessToast open={openSuccess} handleClose={handleClose} text={text} />
      <FailedToast open={openFailed} handleClose={handleClose} text={text} />
    </Box>
  );
}
