import { axios, base_url } from "./imports";

export async function getCategories() {
    let response = await axios.get(base_url + "categories");
    let result = response.data.result?.filter(x => x.hasProducts && x.active) || []
    result.sort((a, b) => {
        return a.rank - b.rank;
    });
    return result;
}

export async function getRecommended() {
    let response = await axios.get(base_url + "getRecommendedProducts");
    return response.data.result;
}

export async function getUserLanguage() {
    let response = await axios.get(base_url + "getUserLanguage");
    return response.data;
}

export async function getAllWelcomeMessages () {
    let response = await axios.get(base_url + "getAllWelcomeMessages");
    return response.data;
}

export async function getLabel(id) {
    let response = await axios.get(base_url + `getLabel/${id}`);
    return response.data;
}

export async function getProductLabel(id) {
    let response = await axios.get(base_url + `getProductLabel/${id}`);
    return response.data;
}

export async function getCategoryLabel(id) {
    let response = await axios.get(base_url + `getCategoryLabel/${id}`);
    return response.data;
}


export async function getAddonsLabel(id) {
    let response = await axios.get(base_url + `getAddonsLabel/${id}`);
    return response.data;
}


export async function getVariantsLabel(id) {
    let response = await axios.get(base_url + `getVariantsLabel/${id}`);
    return response.data;
}

export async function getAllTranslations(id) {
    let response = await axios.get(base_url + `getAllTranslations/${id}`);
    return response.data;
}