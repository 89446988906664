import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Card from '../../../components/Product/Card';
import SuccessToast from '../../../components/common/SuccessToast';
import { getProducts } from '../../../apis';
import { useNavigate, useParams } from 'react-router-dom';
import { b64toBlob } from '../../../utils';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './styles.scss'
import { useDispatch } from 'react-redux';
import { addToFavorites } from '../../../store/actions/userActions';
import { getCategoryLabel, getLabel, getProductLabel } from "../../../apis/common";

export default function Product() {
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [favorites, setFavorites] = useState([])
  const [width, setWidth] = React.useState(window.innerWidth);
  const [categoryLabels, setCategoryLabels] = React.useState([]);
  const [allergen, setAllergen] = React.useState(JSON.parse(localStorage.getItem('allergens')) || []);
  const [nutrition, setNutrition] = React.useState(JSON.parse(localStorage.getItem('nutritions')) || []);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [labels, setLabels] = React.useState([]);
  const [staticData, setstaticData] = React.useState([]);

  // const getTranslatedLabel = (languageKey) => {
  //   const languageItem = labels.find((obj) => obj.base_text === languageKey);
  //   return languageItem ? languageItem.text : languageKey;
  // };

  const getTranslatedLabel = (object, product_id, type, default_text) => {
    //console.log('getTranslatedLabel called with:', { object, product_id, type, default_text });

    if (!labels) {
      return default_text;
    }

    if (!object || !product_id || !type) {
      return default_text;
    }

    const languageItem = labels.find((obj) =>  obj.object === object && obj.objectId === product_id && obj.type === type);
    const result = languageItem ? languageItem.text : default_text;
    //console.log('getTranslatedLabel result:', result);
    return result;
    
  };  

  const getStaticLabel = (languageKey) => {
    const languageStaticItem = staticData.find((obj) => obj.base_language === languageKey);
    return languageStaticItem ? languageStaticItem.translated_language : '';
  };

  const getCatLabel = (id, languageKey) => {
    const languageItem = categoryLabels.find((obj) => obj.objectId == id);
    return languageItem ? languageItem.text : languageKey;
  };

  const fetchCategoryLabel = async () => {
    try {
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        const response = await getCategoryLabel(storedLanguage);
        if (response.code === 200) {
          setCategoryLabels(response.result);
        }
      }
    } catch (error) {
      console.log('Error fetching language data:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      setFavorites(JSON.parse(localStorage.getItem('favorites')) || [])
      setLoading(true);
      setProducts(await getProducts(params.id, nutrition, allergen))
      setLoading(false);
    }
    fetchData();
    window.addEventListener('resize', updateDimension);
    fetchLabel()
    fetchCategoryLabel();

  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [width])

  const updateDimension = () => {
    setWidth(window.innerWidth);
  }

  const toggleToast = () => {
    setToast(!toast);
  }

  const fetchLabel = async () => {
    try {
      const storedLanguage = localStorage.getItem('selectedLanguage');
      const response = await getProductLabel(storedLanguage || '');
      //console.log('API Response for Labels:', response);

      if (response.code === 200) { 
        setLabels(response.result);
        setstaticData(response.fromPrice);
        //console.log('Fetched Labels:', labels);
      }
    } catch (error) {
      console.log('Error fetching language data:', error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  }

  const toggleBackdrop = (data) => {
    //console.log(data);
    setData(data);
    setOpen(!open);
  };

  const handleFavorite = (e, product_id) => {
    e.stopPropagation();
    dispatch(addToFavorites(product_id))
    setFavorites(JSON.parse(localStorage.getItem('favorites')) || [])
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 60, width: "70%" }}>
        <IconButton onClick={() => { navigate(-1) }}>
          <ArrowBackIcon sx={{ fontSize: width <= 500 ? 30 : width < 800 ? 40 : width < 900 ? 40 : 40, color: "black" }} />
        </IconButton>
        <Typography variant='h3' sx={{ fontFamily: 'Clearface', fontWeight: "bold", textAlign: "center", fontSize: width <= 500 ? 30 : width < 800 ? 40 : width < 900 ? 50 : 50 }}>{params.name ? getCatLabel(params.id, params.name) : ''}</Typography>
      </div>

      {
        loading && <CircularProgress color="inherit" sx={{ marginTop: 10, marginBottom: 100 }} />
      }
      {
        loading ? <></> :
          products.length === 0 ? <Typography variant='h5' sx={{ textAlign: "center", marginTop: 10, marginBottom: 100, color: "text.secondary" }}>No data available! Try checking your filters for nutritions and allergens.</Typography> :
            <ImageList sx={{ marginTop: 10, width: "70%" }} cols={width <= 500 ? 1 : width < 800 ? 2 : width < 900 ? 3 : 3}>
              {products.map((item, key) => (
                <ImageListItem
                  key={key}
                  onClick={() => { toggleBackdrop(item) }}
                >
                  <div className="carousel__slide-item carousel__slide-item-img-link">
                    <img
                      src={URL.createObjectURL(b64toBlob(item.image))}
                      srcSet={URL.createObjectURL(b64toBlob(item.image))}
                      alt={item.title}
                      loading="lazy"
                    />
                  </div>
                  <ImageListItemBar
                    title={getTranslatedLabel('product', item.product_id, 'title', item.title)}
                    // subtitle={getStaticLabel('From') + ` € ${item.price}`}
                    subtitle={getStaticLabel('From') + ` € ${item.price.toLocaleString('it-IT')}`}
                    actionIcon={
                      <IconButton
                        sx={{ color: favorites.includes(item.product_id) ? '#99154E' : 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                        onClick={(e) => { handleFavorite(e, item.product_id) }}
                      >
                        <FavoriteIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={toggleBackdrop}
      >
        <Card staticData={staticData} labels={labels} data={data} open={open} toggleToast={toggleToast} toggleBackdrop={toggleBackdrop} handleFavorite={handleFavorite} favorites={favorites} />
      </Backdrop>
      <SuccessToast open={toast} handleClose={handleClose} text='Product added successfully to cart!' />
    </>
  );
}
