import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddressForm from './Address';
import PaymentForm from './PaymentMethod';
import Review from './Review';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../../routes';
import { emptyCart } from '../../../store/actions/userActions';
import { makePayment, placeOrder } from '../../../apis/user';
import { getLabel } from "../../../apis/common";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import TransactionSuccessfull from '../../../components/common/TransactionSuccessfull';
import TransactionFailed from '../../../components/common/TransactionFailed';
import { CircularProgress } from '@mui/material';

function Copyright() {
  return (
    <></>
    // <Typography variant="body2" color="text.secondary" align="center">
    //   {'Copyright © '}
    //   <Link color="inherit" href="https://mui.com/">
    //     {/* Your Website */}
    //   </Link>{' '}
    //   {new Date().getFullYear()}
    //   {'.'}
    // </Typography>
  );
}

function getStepContentForTable(step, data, state = {}, setInstructions) {
  switch (step) {
    case 0:
      return <Review data={data} setInstruction={setInstructions} />;
    default:
      throw new Error('Unknown step');
  }
}

function getStepContentNew(step, data, state = {}, setInstructions, loading = false) {
  switch (step) {
    case 0:
      return <PaymentForm setState={data} state={state} loading={loading} />;
    case 1:
      return <Review data={data} setInstruction={setInstructions} />;
    default:
      throw new Error('Unknown step');
  }
}

function getStepContent(step, data, state = {}, setInstructions) {
  switch (step) {
    case 0:
      return <AddressForm setState={data} state={state} />;
    case 1:
      return <PaymentForm setState={data} state={state} />;
    case 2:
      return <Review data={data} setInstruction={setInstructions} />;
    default:
      throw new Error('Unknown step');
  }
}

const theme = createTheme();

export default function Checkout({ navigation }) {
  const params = useParams();
  const [card, setCard] = React.useState({});
  const [address, setAddress] = React.useState({})
  const [activeStep, setActiveStep] = React.useState(0);
  const [id, setId] = React.useState();
  const [instruction, setInstruction] = React.useState("");
  const { cart, orderMode } = useSelector((store) => store);
  const [labels, setLabels] = React.useState([]);
  const [steps, setSteps] = React.useState([]);
  const [labelsFetched, setLabelsFetched] = React.useState(false);
  const [paymentSuccess, setPaymentSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTranslatedLabel = (languageKey) => {
    const languageItem = labels.find((obj) => obj.language_key === languageKey);
    return languageItem ? languageItem.translated_language : '';
  };

  React.useEffect(() => {
    const fetchLabel = async () => {
      try {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const response = await getLabel(storedLanguage || '');
        if (response.code === 200) {
          setLabels(response.result);
          setLabelsFetched(true);
        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchLabel()

  }, [])


  React.useEffect(() => {
    if (labelsFetched) {
      let shipping_address = getTranslatedLabel('shipping_address');
      let payment_details = getTranslatedLabel('payment_details');
      let review_your_order = getTranslatedLabel('review_your_order');

      if (parseInt(params.mode) === 3) {
        setSteps([shipping_address, payment_details, review_your_order]);
      } else if (parseInt(params.mode) === 2) {
        setSteps([payment_details, review_your_order]);
      } else if (parseInt(params.mode) === 1) {
        setSteps([review_your_order]);
      }
    }
  }, [labelsFetched, params.id]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setError(false);
  };

  const computeBill = (products) => {
    let sum = 0;
    for (let item of products) {
      sum += item.price * item.quantity;
      if (item.addons.length !== 0) {
        let addons = item.addons?.reduce((accumulator, object) => {
          return accumulator + object.price;
        }, 0);
        sum += addons * item.quantity;
      }
      if (item.variant) {
        sum += item.variant.price * item.quantity;
      }
    }
    return sum;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (activeStep === steps.length - 1) {
      const bill = computeBill(cart)
      const paymentMethodObj = {
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: card?.name,
        },
      };

      if (params.mode == 2) {
        try {
          setLoading(true);
          const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);
          console.log(paymentMethodResult);
          const res = await makePayment({ payment_id: paymentMethodResult.paymentMethod.id, amount: bill });
          console.log(res);
          if (res.success) {
            setPaymentSuccess(true);
          } else {
            setPaymentSuccess(false);
            return;
          }
        } catch (err) {
          setLoading(false);
          return;
        } finally {
          setError(false);
          setLoading(false);
        }
      }


      let order = { products: cart.map(function (a) { return { addons: a.addons.map(x => x.addon_id), ingredients: a.ingredients.map(x => x.id), product_id: a.product_id, quantity: a.quantity, price: a.price, variants: a.variants.map(x => x.variant_id) } }) };
      const today = new Date();
      const datetime = today.toUTCString();
      order["datetime"] = datetime;
      order["paymentMethod_id"] = 1;
      order["mode"] = orderMode?.title;
      order["modeInfo"] = orderMode?.info?.table ? `Table: ${orderMode?.info?.table}, Name: ${card.name}` : orderMode?.title === getTranslatedLabel('take_away') ? `Name: ${card.name}` : `Name: ${address.first + " " + address.last}, Address: ${address.address_1} ${address.address_2 || ""}, Phone: ${address.phone}, City: ${address.city}, State: ${address.state}, Zip: ${address.zip}, Country: ${address.country}`
      order["bill"] = bill
      order["status"] = getTranslatedLabel('pending')
      order["instructions"] = instruction;
      order["tableNumber"] = orderMode?.info?.table;
      const response = await placeOrder(order);
      console.log(response);
      setId(response.order_id);
      dispatch(emptyCart(order));
    }
    setActiveStep(activeStep + 1);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        {
          paymentSuccess == null ? <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
              {getTranslatedLabel('checkout')}
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ?
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  {getTranslatedLabel('thank_you_for_your_order')}
                </Typography>
                <Typography variant="subtitle1">
                  {getTranslatedLabel('your_order_number_is')} #{`${id}`}. {getTranslatedLabel('order_confirmation')}
                </Typography>
                <Button onClick={() => { navigate(ROUTES.COMMON.HOME) }} sx={{ mt: 3, ml: 1 }}>
                  {getTranslatedLabel('back_to_menu')}
                </Button>
              </React.Fragment>
              : (
                <React.Fragment>
                  <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    {params.mode == 1 ? getStepContentForTable(activeStep, { cart }, null, setInstruction) : params.mode == 3 ? getStepContent(activeStep, activeStep === 0 ? setAddress : activeStep === 1 ? setCard : { cart, address, card }, activeStep === 0 ? address : card, setInstruction) : getStepContentNew(activeStep, activeStep === 0 ? setCard : { cart, card }, card, setInstruction, loading)}
                    {
                      <Box sx={{ marginTop: 5, visibility: (params.mode == 2 && activeStep == 0) ? 'none' : 'hidden', mb: "5" }}>
                        <CardElement />
                      </Box>
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {(activeStep == params.mode - 1 && params.mode == 2) && (
                        <Button variant="outlined" onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                          {getTranslatedLabel('back') || "Back"}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                      >
                        {activeStep === steps.length - 1 ? getTranslatedLabel('place_order') : getTranslatedLabel('next')}
                        {loading && <CircularProgress size={15} sx={{ ml: 1, color: 'white' }} />}
                      </Button>
                    </Box>
                    {
                      error && <Typography sx={{ display: 'flex', justifyContent: 'flex-end', color: 'red', mt: 2 }}>{"Failed to process your card details!"}</Typography>
                    }
                  </Box>
                </React.Fragment>
              )}
          </Paper> :
            paymentSuccess == true ?
              <TransactionSuccessfull setPaymentSuccess={setPaymentSuccess} /> : <TransactionFailed />

        }
        <Copyright />
      </Container>
    </ThemeProvider >
  );
}
