import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Backdrop from '@mui/material/Backdrop';
import Card from '../../../components/Product/Card';
import SuccessToast from '../../../components/common/SuccessToast';
import { addToFavorites } from '../../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import { getFavorites } from '../../../apis/user';
import { CircularProgress, Typography } from '@mui/material';
import { b64toBlob } from '../../../utils';

export default function Favorite({labels, staticData}) {
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState(false);
  const [favorites, setFavorites] = React.useState(JSON.parse(localStorage.getItem('favorites')) || [])
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const [change, setChange] = React.useState(false);
  const [data, setData] = React.useState();

//  console.log("preferiti: " + favorites);

const getTranslatedLabel = (object, product_id, type, default_text) => {
  //console.log('getTranslatedLabel called with:', { object, product_id, type, default_text, labels });

  if (!labels) {
    return default_text;
  }

  if (!object || !product_id || !type) {
    return default_text;
  }

  const languageItem = labels.find((obj) =>  obj.object === object && obj.objectId === product_id && obj.type === type);
  const result = languageItem ? languageItem.text : default_text;
  //console.log('getTranslatedLabel result:', result);
  return result;
  
};

const getStaticLabel = (languageKey, defaultText) => {
  //console.log('getStaticLabel called with:', { languageKey, staticData });
  const languageStaticItem = staticData.find((obj) => obj.language_key === languageKey);
  return languageStaticItem ? languageStaticItem.translated_language : defaultText;
};

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      //console.log("carico prodotti da preferiti: " + favorites);
      setProducts(await getFavorites(favorites));
      //console.log("prodotti da preferiti: " + products);
      setLoading(false);
    }
    fetchData();
  }, [favorites])


  const dispatch = useDispatch();

  const toggleToast = () => {
    setToast(!toast);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  }

  const toggleBackdrop = (item) => {
    setData(item)
    setOpen(!open);
  };

  const handleFavorite = (e, product_id) => {
    e.stopPropagation();
    dispatch(addToFavorites(product_id))
    setFavorites(JSON.parse(localStorage.getItem('favorites')) || [])
    setChange(!change);
  }

  return (
    <>
      {
        loading ? <CircularProgress color="inherit" sx={{ mt: 2 }} /> :
          products?.length === 0 ? <Typography variant='h5' sx={{ textAlign: "center", marginTop: 10, color: "text.secondary" }}>No favorites so far!</Typography> : <></>
      }
      <ImageList sx={{ marginTop: 5 }} cols={3}>
        {products?.map((item, key) => (
          <ImageListItem
            key={key}
            onClick={() => { toggleBackdrop(item) }}
          >
            <div className="carousel__slide-item carousel__slide-item-img-link">
              <img
                src={URL.createObjectURL(b64toBlob(item?.image))}
                alt={item.title}
                loading="lazy"
              />
            </div>
            <ImageListItemBar
              title={item.title}
              subtitle={`From €${item?.price}`}
              actionIcon={
                <IconButton
                  sx={{ color: favorites.includes(item?.product_id) ? '#99154E' : 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${item.title}`}
                  onClick={(e) => { handleFavorite(e, item?.product_id) }}
                >
                  <FavoriteIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={toggleBackdrop}
      >
        <Card data={data} open={open} toggleToast={toggleToast} toggleBackdrop={toggleBackdrop} handleFavorite={handleFavorite} favorites={favorites} labels={labels} staticData={staticData} />
      </Backdrop>
      <SuccessToast open={toast} handleClose={handleClose} text='Product successfully to cart!' />
    </>
  );
}