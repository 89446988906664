import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import Addon from './Addon';
import NumberPicker from '../common/NumberPicker';
import store from '../../store';
import { addItemToCart } from '../../store/actions/userActions';
import { b64toBlob } from '../../utils';
import Variant from './Variant';
import { getAddonsLabel, getCategoryLabel, getVariantsLabel } from "../../apis/common";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProductCard({ open, toggleBackdrop, toggleToast, data, handleFavorite, favorites, labels, staticData }) {
  const [addons, setAddons] = React.useState([]);
  const [ingredients, setIngredients] = React.useState([]);
  const [price, setPrice] = React.useState();
  const [quantity, setQuantity] = React.useState(1);
  const [variant, setVariant] = React.useState([]);
  const [selectedVariants, setSelectedVariants] = React.useState([]);
  const [variants, setVariants] = React.useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [condtionalHeight, setConditionalHeight] = React.useState(width <= 500 ? { height: "100%" } : {});


  // Utility function to format the price
  const formatPrice = (amount) => {
    return parseFloat(amount).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
  };

  // console.log('pref card labels:', labels);
  // console.log('pref card staticdata:', staticData);


  const getTranslatedLabel = (object, product_id, type, default_text) => {
    //console.log('getTranslatedLabel called with:', { object, product_id, type, default_text });

    const languageItem = labels?.find((obj) => obj.object == object && obj.objectId == product_id && obj.type == type);
    //console.log('getTranslatedLabel languageItem:', languageItem);
    const result = languageItem ? languageItem.text : default_text;
    //console.log('getTranslatedLabel result:', result);
    return result;

  };

  const getStaticLabel = (languageKey) => {
    //console.log('getStaticLabel called with:', { languageKey });
    const languageStaticItem = staticData?.find((obj) => obj.language_key === languageKey);
    //console.log('getStaticLabel staticData:', staticData);
    //console.log('getStaticLabel languageStaticItem:', languageStaticItem);

    return languageStaticItem ? languageStaticItem.translated_language : '';
  };


  React.useEffect(() => {
    if (data?.price) {
      let base_price = data?.price;
      base_price += addons.filter(x => x?.selected).reduce((accumulator, object) => {
        return accumulator + object.price;
      }, 0);
      base_price += selectedVariants.filter(x => x?.selected).reduce((accumulator, object) => {
        return accumulator + object.price;
      }, 0);
      setPrice(base_price * quantity)
    }
  }, [addons, variant, quantity, selectedVariants])

  React.useEffect(() => {
    setAddons(data?.AddOns || []);
    setIngredients(data?.Ingredients);
    setPrice(data?.price);
    if (data?.Variants) {
      let groupNames = data?.Variants?.map(x => x.VariantGroup?.title)
      groupNames = new Set(groupNames);
      groupNames = [...groupNames];
      let variants_ = [];
      for (let group of groupNames) {
        variants_.push({ key: group, value: data?.Variants?.filter(x => x.VariantGroup?.title === group) })
      }
      setVariants(variants_);
    }
    setSelectedVariants(data?.Variants || []);
    setQuantity(1);
    window.addEventListener('resize', updateDimension);
  }, [data])

  React.useEffect(() => {
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [width])

  const updateDimension = () => {
    setWidth(window.innerWidth);
  }
  

  const addToCart = () => {
    toggleBackdrop();
    toggleToast();
    const selectedAddons = addons.filter(x => x?.selected);
    const selectedVariants_ = selectedVariants.filter(x => x?.selected);
    const selectedIngredients = ingredients.filter(x => x?.selected)
    const order = { ...data, addons: selectedAddons, quantity, variants: selectedVariants_, ingredients: selectedIngredients }
    store.dispatch(addItemToCart(order));
  }

  return (
    <Card sx={[{ width: 600, overflowY: "scroll", maxHeight: "95vh" }, condtionalHeight]}>
      <CardHeader
        action={
          <IconButton aria-label="settings"
            onClick={() => { toggleBackdrop(!open); setQuantity(1) }}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{ fontFamily: 'Clearface' }}
        title={getTranslatedLabel('product', data?.product_id, 'title', data?.title)}
        subheader={getStaticLabel('Price') + ` : ${formatPrice(price)}`}
      />
      <CardMedia
        component="img"
        height="250"
        image={URL.createObjectURL(b64toBlob(data?.image || ""))}
        alt={data?.title}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {data?.description && getTranslatedLabel('product', data.product_id, 'description', data.description)}
        </Typography>
      </CardContent>
      <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: "space-between" }}>
        <div>
          {
            data?.AddOns?.length > 0 &&
            <CardContent sx={{ flex: 1 }}>
              <Typography variant="body" color="text.primary">
                {getStaticLabel('addons') || "Add ons"}:
              </Typography>
              <Addon data={data?.AddOns} addons={addons} price={formatPrice} setPrice={setPrice} setAddons={setAddons} quantity={quantity} objType="addons" />
            </CardContent>
          }

          {variants && variants.length > 0 && (
            <>
              <Typography variant="body" color="text.primary" sx={{ ml: 2 }}>
                {getStaticLabel('variants') || "Variants"}:
              </Typography>
              {variants.map((variant_, index) => (
                <CardContent sx={{ flex: 1 }}>
                  {
                    variant_.key && (
                      <Typography variant="body" color="text.primary">
                        {getStaticLabel(variant_.key) || variant_.key}:
                      </Typography>
                    )
                  }
                  <Variant data={variant_?.value} variant={selectedVariants} price={formatPrice} setPrice={setPrice} setVariant={setSelectedVariants} quantity={quantity} />
                </CardContent>
              ))}
            </>
          )}
        </div>
        <div>
          {
            data?.TasteProfiles?.filter(x => x.type === "Allergen").length > 0 ?
              <CardContent sx={{ flex: 1 }}>
                <Typography variant="body" color="text.primary">
                {getStaticLabel('allergens') || "Allergens"}:
                </Typography>
                <Addon data={data?.TasteProfiles?.filter(x => x.type === "Allergen")} objType="Allergen" />
              </CardContent> : <></>
          }
          {
            data?.TasteProfiles?.filter(x => x.type === "Nutrition").length > 0 ?
              <CardContent sx={{ flex: 1 }}>
                <Typography variant="body" color="text.primary">
                {getStaticLabel('nutrition') || "Nutrition"}:
                </Typography>
                <Addon data={data?.TasteProfiles?.filter(x => x.type === "Nutrition")} objType="Nutrition" />
              </CardContent> : <></>
          }
        </div>
      </div>
      <div>
        {
          data?.Ingredients?.length > 0 ?
            <CardContent sx={{ flex: 1 }}>
              <Typography variant="body" color="text.primary">
                {getStaticLabel('select_ingredients_to_remove') || "Select Ingredients to Remove"}:
              </Typography>
              <Addon data={data?.Ingredients} addons={ingredients} setAddons={setIngredients} isIngredient={true} objType="ingredient" />
            </CardContent> : <></>
        }
      </div>

      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites" onClick={(e) => { handleFavorite(e, data?.product_id) }}>
          <FavoriteIcon sx={{ color: favorites.includes(data?.product_id) ? "#99154E" : "none" }} />
        </IconButton>

          {/* <IconButton aria-label="share">
            <NumberPicker value={quantity} setValue={setQuantity} price={price} setPrice={setPrice} productPrice={data?.price} />
          </IconButton>
          <ExpandMore>
            <IconButton disabled={quantity === 0}>
              <ShoppingCartIcon onClick={addToCart} />
            </IconButton>
          </ExpandMore> */}

      </CardActions>
    </Card>
  );
}
