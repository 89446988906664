import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box, IconButton } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector } from "react-redux";
import { b64toBlob } from "../../utils";
import "./styles.css";
import {getLabel} from '../../apis/common';

function Footer() {
    const info = useSelector((store) => store.info)
    const [labels, setLabels] = React.useState([]);
    const getTranslatedLabel = (languageKey) => {
        const languageItem = labels.find((obj) => obj.language_key === languageKey);
        return languageItem ? languageItem.translated_language : '';
    };

    React.useEffect(() => {
        const fetchLabel = async () => {
            try {
                const storedLanguage = localStorage.getItem('selectedLanguage');
                const response = await getLabel(storedLanguage || '');
                if (response.code === 200) {
                    setLabels(response.result);
                }
            } catch (error) {
                console.log('Error fetching language data:', error);
            }
        };

        fetchLabel()

    }, [])
    return (
        <div className="footerParentDiv my-1">
            <div className="content">

                    <div className="logo1">
                        <div className="homeLink">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Box component="img" src={URL.createObjectURL(b64toBlob(info.image || ""))} sx={{ height: 50, cursor: "pointer" }} />
                                &nbsp;<Typography variant="h4"> {getTranslatedLabel('restaurant_name')}</Typography>
                            </div>
                        </div>

                        <div className="tagline">
                            <p>
                                {info.tagline}
                            </p>
                        </div>
                    </div>


                <div>
                    <div className="heading">
                        <p>Get in touch</p>
                    </div>
                    <div className="contact">

                        <ButtonGroup
                            variant="text"
                            orientation="vertical"
                            aria-label="text button group"
                            color="warning"
                            sx={{ display: "flex", flexDirection: "row" }}
                        >
                            <Link href={`${info.fb}`} target="_blank">
                                <IconButton sx={{ color: "white" }}>
                                    <FacebookIcon />
                                </IconButton>
                            </Link>
                            <Link href={`${info.insta}`} target="_blank">
                                <IconButton sx={{ color: "white" }}>
                                    <InstagramIcon />
                                </IconButton>
                            </Link>
                            <IconButton sx={{ color: "white" }}>
                                <WhatsAppIcon />
                            </IconButton>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <div className="footer">
                <Typography variant="body4" color="#FFFF" align="center">
                    {"Copyright © "}
                    <Link color="inherit" href="#">
                        Personal Waiter
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {". All rights reserved."}
                </Typography>
            </div>
        </div>
    );
}

export default Footer;
