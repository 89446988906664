import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { CircularProgress, Typography } from '@mui/material';
import { getBusinessInfo } from '../../../apis';
import { b64toBlob } from '../../../utils';
import { updateBusinessInfo } from '../../../apis/admin';
import SuccessToast from '../../../components/common/SuccessToast';
import FailedToast from '../../../components/common/FailedToast';
import { useNavigate } from 'react-router-dom';
import './styles.scss'
import store from '../../../store';
import ROUTES from '../../../routes';

function BusinessInfo() {
  const [email, setEmail] = useState();
  const [tos, setTos] = useState();
  const [about, setAbout] = useState();
  const [ingredients, setIngredients] = useState();
  const [allergens, setAllergens] = useState();
  const [image, setImage] = useState();
  const [path, setPath] = useState();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [text, setText] = useState();
  const [openSuccess, setOpenSucess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [fb, setFb] = useState();
  const [insta, setInsta] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [welcome, setWelcome] = useState();
  const [joinUsText, setJoinUsText] = useState();
  const [joinUsLink, setJoinUsLink] = useState();
  const [joinUsImage, setJoinUsImage] = useState();
  const [tagline, setTagline] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const state = store.getState();
    if(state.mode != "admin"){
      navigate(ROUTES.ADMIN.LOGIN);
    }
    async function fetchData() {
      setLoading(true);
      const response = await getBusinessInfo();
      setLoading(false);
      setEmail(await response.email);
      setAbout(await response.about);
      setIngredients(await response.ingredients);
      setAllergens(await response.allergens);
      setImage(await response.image);
      setTos(await response.termsOfSale);
      setPath(await response.path);
      setFb(await response.fb);
      setInsta(await response.insta);
      setWhatsapp(await response.whatsapp);
      setWelcome(await response.welcome);
      setJoinUsText(await response.joinUsText)
      setJoinUsLink(await response.joinUsLink)
      setJoinUsImage(await response.joinUsImage);
      setTagline(await response.tagline);
    }
    fetchData()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const updatedInfo = {
      email: data.get("email"),
      about: data.get("about"),
      allergens: data.get("allergens"),
      ingredients: data.get("ingredients"),
      tos: data.get("tos"),
      file: file,
      fb: data.get("fb"),
      insta: data.get("insta"),
      whatsapp: data.get("whatsapp"),
      welcome: data.get("welcome"),
      joinUsText: data.get("joinUsText"),
      joinUsLink: data.get("joinUsLink"),
      tagline: data.get("tagline")
    };

    let form = new FormData();
    form.append('email', updatedInfo.email);
    form.append('about', updatedInfo.about);
    form.append('allergens', updatedInfo.allergens);
    form.append('ingredients', updatedInfo.ingredients);
    form.append('termsOfSale', updatedInfo.tos);
    form.append('path', path);
    if (!file) {
      const file_ = b64toBlob(image)
      form.append('file', file_)
    } else {
      form.append('file', file)
    }
    if (!file2) {
      const file_ = b64toBlob(joinUsImage)
      form.append('file', file_)
    } else {
      form.append('file', file2)
    }

    form.append('fb', updatedInfo.fb)
    form.append('insta', updatedInfo.insta)
    form.append('whatsapp', updatedInfo.whatsapp)
    form.append('welcome', updatedInfo.welcome)
    form.append('joinUsText', updatedInfo.joinUsText)
    form.append('joinUsLink', updatedInfo.joinUsLink)
    form.append('tagline', updatedInfo.tagline)

    setLoader(true);
    const response = await updateBusinessInfo(form);
    setLoader(false);
    if (response.code === 200) {
      setText("Information successfully updated!");
      setOpenSucess(true);
    } else {
      setText("There was an error in updating the information!");
      setOpenFailed(true);
    }
    navigate(0);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSucess(false);
    setOpenFailed(false);
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "60%"
      }}
    >
      <Typography component="h1" variant="h3" sx={{ fontWeight: "bold", fontFamily: 'Clearface' }}>
        Business Information
      </Typography>
      {
        loading ? <CircularProgress color="inherit" sx={{ mt: 5 }} /> :
          <Container component="main">
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <TextField
                    value={email}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    sx={{ width: "90%" }}
                  />
                  <TextField
                    value={allergens}
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    id="allergens"
                    label="Allergens"
                    name="allergens"
                    autoComplete="allergens"
                    autoFocus
                    rows={5}
                    onChange={(e) => {
                      setAllergens(e.target.value);
                    }}
                    sx={{ width: "90%" }}
                  />
                </div>
                <Box style={{ display: "flex", flexDirection: "column", width: 230 }}>
                  <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => {
                    document.querySelector('input[id="image_input"]').click();
                  }}>
                    <input
                      type="file"
                      hidden
                      name="logo"
                      id="image_input"
                      onChange={(e) => { setFile(e.target.files[0]) }}
                      style={{ display: "none" }}
                    />
                    <img
                      component="img"
                      style={{ height: 230, width: 230, objectFit: "cover", borderRadius: 10 }}
                      alt="Logo"
                      src={file ? URL.createObjectURL(file) : URL.createObjectURL(b64toBlob(image || ""))}
                    />
                  </div>
                </Box>
              </div>
              <TextField
                value={about}
                margin="normal"
                required
                fullWidth
                multiline
                id="about"
                label="About"
                name="about"
                autoComplete="about"
                autoFocus
                rows={5}
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
              />
              <TextField
                value={tos}
                margin="normal"
                required
                fullWidth
                multiline
                id="tos"
                label="Terms of Sale"
                name="tos"
                autoComplete="tos"
                autoFocus
                rows={5}
                onChange={(e) => {
                  setTos(e.target.value);
                }}
              />
              <TextField
                value={ingredients}
                margin="normal"
                required
                fullWidth
                multiline
                id="ingredients"
                label="Ingredients"
                name="ingredients"
                autoComplete="ingredients"
                autoFocus
                rows={5}
                onChange={(e) => {
                  setIngredients(e.target.value);
                }}
              />
              <TextField
                value={welcome}
                margin="normal"
                required
                fullWidth
                multiline
                rows={5}
                id="welcome"
                onChange={(e) => {
                  setWelcome(e.target.value);
                }}
                label="Welcome Text"
                name="welcome"
                autoComplete="welcome"
                autoFocus
              />
              <TextField
                value={tagline}
                margin="normal"
                required
                fullWidth
                id="tagline"
                label="Tagline"
                name="tagline"
                autoComplete="tagline"
                autoFocus
                onChange={(e) => {
                  setTagline(e.target.value);
                }}
              />
              <TextField
                value={fb}
                margin="normal"
                fullWidth
                id="fb"
                onChange={(e) => {
                  setFb(e.target.value);
                }}
                label="Facebook link"
                name="fb"
                autoComplete="fb"
                autoFocus
              />
              <TextField
                value={insta}
                margin="normal"
                fullWidth
                id="insta"
                onChange={(e) => {
                  setInsta(e.target.value);
                }}
                label="Instagram Link"
                name="insta"
                autoComplete="insta"
                autoFocus
              />
              <TextField
                value={whatsapp}
                margin="normal"
                fullWidth
                id="whatsapp"
                onChange={(e) => {
                  setWhatsapp(e.target.value);
                }}
                label="Whatsapp Number"
                name="whatsapp"
                autoComplete="whatsapp"
                autoFocus
              />
              <TextField
                value={joinUsText}
                margin="normal"
                fullWidth
                id="joinUsText"
                onChange={(e) => {
                  setJoinUsText(e.target.value);
                }}
                label="Join Us Text"
                name="joinUsText"
                autoComplete="joinUsText"
                autoFocus
              />
              <TextField
                value={joinUsLink}
                margin="normal"
                fullWidth
                id="joinUsLink"
                onChange={(e) => {
                  setJoinUsLink(e.target.value);
                }}
                label="Join Us Link"
                name="joinUsLink"
                autoComplete="joinUsLink"
                autoFocus
              />
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => {
                  document.querySelector('input[id="image_input_2"]').click();
                }}>
                  <input
                    type="file"
                    hidden
                    name="banner"
                    id="image_input_2"
                    onChange={(e) => { setFile2(e.target.files[0]) }}
                    style={{ display: "none" }}
                  />
                  <img
                    component="img"
                    style={{ height: 230, objectFit: "cover", borderRadius: 10 }}
                    alt="Join us banner image"
                    src={file2 ? URL.createObjectURL(file2) : URL.createObjectURL(b64toBlob(joinUsImage || ""))}
                  />
                </div>
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update Info
                {
                  loader && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                }

              </Button>
            </Box>
          </Container>
      }
      <SuccessToast open={openSuccess} handleClose={handleClose} text={text} />
      <FailedToast open={openFailed} handleClose={handleClose} text={text} />
    </Box >
  );
}

export default BusinessInfo;
