import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { IconButton, ImageListItemBar, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getSubCategories } from '../../../apis';
import { b64toBlob } from '../../../utils';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './styles.scss'
import { getCategoryLabel } from "../../../apis/common";

export default function Category() {
  const [subCats, setSubCats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [categoryLabels, setCategoryLabels] = React.useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const applyImportantStyles = () => {
    //console.log('Applying important styles');
    const elements = document.querySelectorAll('.MuiImageListItem-woven:nth-of-type(2n)');
    elements.forEach(el => {
      el.style.cssText = 'height: 100% !important;';
    });
  };

  useEffect(() => {
    applyImportantStyles();
  }, []);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          applyImportantStyles();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect(); // Clean up observer on component unmount
  }, []);


  useEffect(() => {
    applyImportantStyles();
    window.addEventListener('resize', applyImportantStyles);  
    return () => {
      window.removeEventListener('resize', applyImportantStyles);
    };
  }, []);  


  useEffect(() => {
    window.scrollTo(0, 0)
    async function fetchData() {
      setLoading(true);
      const response = await getSubCategories(params.id)
      if (response.length === 0) {
        navigate(`/product/${params.name}/${params.id}`, { replace: true })
      }
      setSubCats(response);
      setLoading(false);
      window.addEventListener('resize', updateDimension);
    }
    fetchData();
    fetchCategoryLabel();
  }, [])

  const getCatLabel = (languageKey) => {
    const languageItem = categoryLabels.find((obj) => obj.base_text === languageKey);
    return languageItem ? languageItem.text : '';
  };

  const getCatLabel2 = (catid, languageKey, item) => {
    const languageItem = categoryLabels.find((obj) => obj.objectId == catid);
    return languageItem ? languageItem.text : languageKey;
  };

  const fetchCategoryLabel = async () => {
    try {
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        const response = await getCategoryLabel(storedLanguage);
        if (response.code === 200) {
          setCategoryLabels(response.result);
        }
      }
    } catch (error) {
      console.log('Error fetching language data:', error);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [width])

  const updateDimension = () => {
    setWidth(window.innerWidth);
  }

  const handleClick = async (name, id) => {
    const response = await getSubCategories(id);
    if (response.length) {
      navigate(`/category/${name}/${id}`);
      navigate(0);
    } else {
      navigate(`/product/${name}/${id}`)
    }
  }


  if (loading) {
    applyImportantStyles();
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 60, width: "70%" }}>
        <IconButton onClick={() => { navigate(-1) }}>
          <ArrowBackIcon sx={{ fontSize: width <= 500 ? 30 : width < 800 ? 40 : width < 900 ? 40 : 40, color: "black" }} />
        </IconButton>
        <Typography variant='h3' sx={{ fontFamily: 'Clearface', fontWeight: "bold", textAlign: "center", fontSize: width <= 500 ? 30 : width < 800 ? 40 : width < 900 ? 50 : 50 }}>{params.name ? getCatLabel2(params.id, params.name, params) : ''}</Typography>
      </div>
      {
        loading && <CircularProgress color="inherit" sx={{ marginTop: 10, marginBottom: 100 }} />
      }
      {
        loading ? <></> :
          subCats.length === 0 ? <Typography variant='h5' sx={{ textAlign: "center", marginTop: 10, marginBottom: 100, color: "text.secondary" }}>No data available.</Typography> :

            <ImageList variant="woven" cols={width <= 500 ? 1 : width < 800 ? 2 : width < 900 ? 3 : 3} gap={8} sx={{ marginTop: 10, width: "70%", height: "100%" }}>
              {subCats.map((item) => (
                <ImageListItem
                  key={item.img}
                  onClick={() => { handleClick(item.title, item.category_id) }}
                >
                  <div className="carousel__slide-item carousel__slide-item-img-link">
                    <img
                      src={URL.createObjectURL(b64toBlob(item?.image || ""))}
                      srcSet={URL.createObjectURL(b64toBlob(item?.image || ""))}
                      alt={item.title}
                      loading="lazy"
                    />
                  </div>
                  <ImageListItemBar
                    sx={{
                      background:
                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    title={item.title ? getCatLabel2(item.category_id, item.title) : ''}
                    position="top"
                    actionPosition="left"
                  />
                </ImageListItem>
              ))}
            </ImageList>
      }
    </>
  );
}
