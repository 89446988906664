import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'
import { addCategory, getCategoriesBrief, updateCategory } from '../../../apis';
import { b64toBlob } from '../../../utils';
import dummy from '../../../assets/images/dummy.jpeg';

function CategoryEditCard({ data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess, setData }) {
    const [title, setTitle] = useState("");
    const [parent, setParent] = useState(data?.parentCategory || -1);
    const [file, setFile] = useState();
    const [rank, setRank] = useState(0);
    const [active, setActive] = useState(data?.active || false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTitle(data?.title || "");
        setParent(data?.parentCategory || -1);
        setRank(data?.rank || 1);
        setActive(data?.active || false);
        setFile();
    }, [data])

    useEffect(() => {
        async function fetchData() {
            setCategories(await getCategoriesBrief());
        }
        fetchData();
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data_ = new FormData(event.currentTarget);
        const updatedCategory = {
            id: data?.category_id,
            title: data_.get("title"),
            description: data_.get("description"),
            category: data_.get("category"),
            active: active,
            rank: data_.get("rank"),
            file: file
        };

        let form = new FormData();
        form.append('title', updatedCategory.title);
        form.append('description', updatedCategory.description);
        form.append('parentCategory', updatedCategory.category);
        form.append('rank', updatedCategory.rank);
        form.append('active', updatedCategory.active);
        form.append('file', file)

        if (data) {
            setLoading(true);
            const response = await updateCategory(updatedCategory.id, form);
            setLoading(false);
            if (response.code === 200) {
                setText("Category successfully updated!");
                setOpenSucess(true);
            } else {
                setText("There was an error while updating the Category!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addCategory(form);
            setLoading(false);
            if (response.code === 200) {
                setText("Category successfully added!");
                setOpenSucess(true);
            } else {
                setText("There was an error while adding the Category!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({});
    };

    const changeParent = (event) => {
        setParent(event.target.value);
    };

    return (
        <Card sx={{ padding: 2, width: "46%" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={closeBackdrop}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Category" : "Add new Category"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <TextField
                                value={title}
                                margin="normal"
                                required
                                fullWidth
                                id="title"
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                label="Title"
                                name="title"
                                autoComplete="title"
                                autoFocus
                            />
                        </div>
                        <Box style={{ display: "flex", flexDirection: "column", width: 230 }}>
                            <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => {
                                document.querySelector('input[id="image_input"]').click();
                            }}>
                                <input
                                    type="file"
                                    hidden
                                    id="image_input"
                                    onChange={(e) => { setFile(e.target.files[0]) }}
                                    style={{ display: "none" }}
                                />
                                <img
                                    component="img"
                                    style={{ height: 230, width: 230, objectFit: "cover", borderRadius: 10 }}
                                    alt="Logo"
                                    src={file ? URL.createObjectURL(file) : data?.image ? URL.createObjectURL(b64toBlob(data?.image)) : dummy}
                                />
                            </div>
                        </Box>
                    </div>
                    <TextField
                        value={rank}
                        margin="normal"
                        required
                        fullWidth
                        id="rank"
                        onChange={(e) => {
                            setRank(e.target.value);
                        }}
                        label="Rank"
                        name="rank"
                        autoComplete="rank"
                        autoFocus
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="category"
                            name='category'
                            value={parent}
                            label="Category"
                            onChange={changeParent}
                        >
                            <MenuItem value={-1}>N/A</MenuItem>
                            {
                                categories.map((category, key) => {
                                    return (
                                        category.title !== title && <MenuItem value={category.category_id}>{category.title}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Checkbox checked={active} onClick={() => { setActive(!active) }} />} label="Activiy Status" />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update Info" : "Add Category"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default CategoryEditCard;
